li > p {
    margin-bottom: 0;
}

.patreon {
    margin: 0;
}

.sponsor {
  display: flex !important;
  align-content: center;
  align-items: center;
}

.btn:hover {
  border-color: #000;
}

th.halign-left,td.halign-left {
    text-align:left;
}
